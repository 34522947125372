/** @format */

import * as actionTypes from 'stateContainer/action-types';

const initialState = {
  requesting: false,
  data: {}
};

export const currentUserReducer = (state = initialState, action) => {
  switch (action.type) {
    /** Register company **/
    case actionTypes.REGISTER_COMPANY_REQUESTED:
      return {
        ...state,
        requesting: true,
        data: { ...state.data, ...action.payload }
      };

    case actionTypes.REGISTER_COMPANY_SUCCESS:
      return {
        ...state,
        ...action.payload,
        requesting: false,
        chosenCompany: {
          ...state.chosenCompany,
          listOfSignees: action.listOfSignees
        }
      };

    case actionTypes.REGISTER_COMPANY_SKIPPED:
      return { ...state, requesting: false };

    case actionTypes.REGISTER_COMPANY_FAILED:
      return {
        ...state,
        requesting: false,
        data:
          (action && action.data && { ...state.data, ...action.data }) ||
          state.data
      };

    /**  reducers for fetching existing registrations of company and user **/

    case actionTypes.FETCH_CURRENT_REGISTRATION_STATE_OF_CHOSENCOMPANY_REQUESTED:
      return {
        ...state,
        requesting: true
      };

    case actionTypes.FETCH_CURRENT_REGISTRATION_STATE_OF_CHOSENCOMPANY_SUCCESS:
      return {
        ...state,
        requesting: false,
        existingRecords: {
          ...state.existingRecords,
          ...action.existingRecords
        },
        chosenCompany: {
          ...state.chosenCompany,
          ...action.chosenCompany
        }
      };

    case actionTypes.FETCH_CURRENT_REGISTRATION_STATE_OF_CHOSENCOMPANY_SKIPPED:
      return { ...state, requesting: false };

    case actionTypes.FETCH_CURRENT_REGISTRATION_STATE_OF_CHOSENCOMPANY_FAILED:
      return {
        ...state,
        requesting: false
      };

    /** reducers for selection of company to register **/

    case actionTypes.COMPANY_CHOICE_REQUESTED:
      return {
        ...state
      };

    case actionTypes.COMPANY_CHOICE_SUCCESS:
      return {
        ...state,
        chosenCompany: { ...state.chosenCompany, ...action.payload }
      };

    /** base-data-actions **/

    case actionTypes.FETCH_BASEDATA_REQUESTED:
      return { ...state, requesting: true };

    case actionTypes.FETCH_BASEDATA_SUCCESS:
      return {
        ...state,
        existingRecords: {
          ...state.existingRecords,
          ...action.existingRecords
        },
        requesting: false
      };

    case actionTypes.FETCH_BASEDATA_FAILED:
      return { ...state, requesting: false };

    case actionTypes.SET_TERMS_POLICY_AGREED:
      return { ...state, ...action.payload };

    case actionTypes.SET_PAYMENT_MODE_CHOSEN:
      return { ...state, ...action.payload };

    case actionTypes.FETCH_REQUESTID_FROM_URL:
      return { corporateApprovalData: action.payload };

    case actionTypes.STORE_SIGNEE_COMBINATION_REQUESTED:
      return { ...state, chosenSigneeCombination: action.payload };

    case actionTypes.CREATE_SIGNEE_COMBINATION_ON_COMPANY_REQUESTED:
      return { ...state, requesting: true };

    case actionTypes.CREATE_SIGNEE_COMBINATION_ON_COMPANY_SUCCESS:
      return { ...state, requesting: false };

    case actionTypes.CREATE_SIGNEE_COMBINATION_ON_COMPANY_FAILED:
      return { ...state, requesting: false };

    case actionTypes.CONFIRM_COMPANY_REGISTRATION_DATA:
      return { ...state, data: action.payload, isSigneeApprovalFlow: true };

    case actionTypes.APPROVE_COMPANY_REGISTRATION_REQUESTED:
      return {
        ...state,
        requesting: true,
        error: false
      };

    case actionTypes.APPROVE_COMPANY_REGISTRATION_SUCCESS:
      return {
        ...state,
        requesting: false,
        data: { ...state.data, ...action.payload },
        error: false
      };

    case actionTypes.APPROVE_COMPANY_REGISTRATION_FAILED:
      return {
        ...state,
        requesting: false,
        error: true
      };

    case actionTypes.CREATE_EMPLOYEE_REQUESTED:
      return {
        ...state,
        requesting: true,
        isCreatingEmployee: true
      };

    case actionTypes.CREATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        requesting: false,
        isCreatingEmployee: false,
        existingRecords: {
          ...state.existingRecords,
          listOfEmployees: action.payload,
          company: {
            ...state.existingRecords.company,
            listOfEmployees: action.payload
          }
        }
      };

    case actionTypes.CREATE_EMPLOYEE_FAILED:
      return {
        ...state,
        requesting: false,
        isCreatingEmployee: false,
        error: true
      };

    case actionTypes.DELETE_EMPLOYEE_REQUESTED:
      return {
        ...state,
        requesting: true
      };

    case actionTypes.DELETE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        requesting: false,
        existingRecords: {
          ...state.existingRecords,
          listOfEmployees:
            action.payload || state.existingRecords.listOfEmployees,
          company: {
            ...state.existingRecords.company,
            listOfEmployees:
              action.payload || state.existingRecords.listOfEmployees
          }
        }
      };

    case actionTypes.DELETE_EMPLOYEE_FAILED:
      return {
        ...state,
        requesting: false,
        error: true
      };

    case actionTypes.UPDATE_LIST_OF_EMPLOYEES_SUCCESS:
      return {
        ...state,
        requesting: false,
        existingRecords: {
          ...state.existingRecords,
          listOfEmployees:
            action.payload || state.existingRecords.listOfEmployees,
          company: {
            ...state.existingRecords.company,
            listOfEmployees:
              action.payload || state.existingRecords.listOfEmployees
          }
        }
      };

    case actionTypes.CREATE_EMPLOYEE_IN_BULK_REQUESTED:
      return {
        ...state,
        requesting: true,
        isUploadingInBulk: true
      };

    case actionTypes.CREATE_EMPLOYEE_IN_BULK_SUCCESS:
      return {
        ...state,
        requesting: false,
        isUploadingInBulk: false
      };

    case actionTypes.CREATE_EMPLOYEE_IN_BULK_FAILED:
      return {
        ...state,
        requesting: false,
        isUploadingInBulk: false
      };

    default:
      return state;
  }
};
